export const subscribeToConversation = /* GraphQL */ `
  subscription SubscribeToConversation($conversationId: ID!) {
    subscribeToConversation(receivedBy: $conversationId) {
      conversationId
      createdAt
      data
      messageId
      receivedAt
      receivedBy
      readAt
      sentAt
      sentBy
      tempId
      text
      type
      senderName
      senderPicture
    }
  }
`;
export const subscribeToUserPresence = /* GraphQL */ `
  subscription SubscribeToUserPresence($familyId: ID!) {
    subscribeToUserPresence(familyId: $familyId) {
      contactId
      familyId
      presenceStatus
    }
  }
`;
export const subscribeToVitalReadings = /* GraphQL */ `
  subscription SubscribeToVitalReadings(
    $familyId: String!
    $contactId: String
  ) {
    subscribeToVitalReadings(familyId: $familyId, contactId: $contactId) {
      latestReading {
        contactId
        createdAt
        deviceId
        familyId
        hardwareId
        isManual
        readAt
        reading
        readingId
        vitalTypeId
      }
      readingCount
      vitalTypeId
      vitalTypeName
      contactId
      activityStatus
    }
  }
`;
export const subscribeToUserDeviceStatus = /* GraphQL */ `
  subscription SubscribeToUserDeviceStatus($contactId: ID!) {
    subscribeToUserDeviceStatus(contactId: $contactId) {
      userDeviceId
      contactId
      battery
      wifi
      bluetooth
    }
  }
`;
export const subscribeToNewMemories = /* GraphQL */ `
  subscription SubscribeToNewMemories($familyId: ID!) {
    subscribeToNewMemories(familyId: $familyId) {
      createdAt
      data
      description
      familyId
      notificationId
      senderContactId
      senderName
      senderPicture
      status
      title
      type
    }
  }
`;

