import * as moment from 'moment';
import RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';

export class AudioRecordingService {

    stream;
    recorder;
    interval;
    startTime;
    _recorded = new Subject();
    _recordingTime = new Subject();
    _recordingFailed = new Subject();

    getRecordedBlob(){
      return this._recorded.asObservable();
    }

    getRecordedTime() {
      return this._recordingTime.asObservable();
    }

    recordingFailed(){
      return this._recordingFailed.asObservable();
    }

    startRecording() {
        if (this.recorder) {
            // It means recording is already started or it is already recording something
            return;
        }

        this._recordingTime.next('00:00');
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then(s => {
            this.stream = s;
            this.record();
        }).catch(error => {
            this._recordingFailed.next();
        });
    }

    abortRecording() {
        this.stopMedia();
    }

    record() {
        this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
            type: 'audio',
            mimeType: 'audio/mp3'
        });

        this.recorder.record();
        this.startTime = moment();
        this.interval = setInterval(
            () => {
                const currentTime = moment();
                const diffTime = moment.duration(currentTime.diff(this.startTime));
                const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
                this._recordingTime.next(time);
            },
            500
        );
    }

    toString(value) {
        let val = value;
        if (!value) {
            val = '00';
        }
        if (value < 10) {
            val = '0' + value;
        }
        return val;
    }

    stopRecording() {
        if (this.recorder) {
            this.recorder.stop((blob) => {
                if (this.startTime) {
                    const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
                    this.stopMedia();
                    this._recorded.next({ blob: blob, title: mp3Name });
                }
            }, () => {
                this.stopMedia();
                this._recordingFailed.next();
            });
        }
    }

    stopMedia() {
        if (this.recorder) {
            this.recorder = null;
            clearInterval(this.interval);
            this.startTime = null;
            if (this.stream) {
                this.stream.getAudioTracks().forEach(track => track.stop());
                this.stream = null;
            }
        }
    }
}
