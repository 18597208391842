export const Time = {
    hour :12,
    am :' AM',
    pm :' PM',
    digit :'2-digit',
    sec :'sec',
    min :'min',
    readHour :'hour',
    custom :'before a day'
}

export const Message = {
    limit: 300,
    order: 'Asc',
    mediaCount: 1,
    initialLoad: 'InitialLoad'
}

export const MessageType = {
    text: 'Text',
    audio: 'Audio',
    chat: 'Chat'
}

export const Receipt =  {
    upload: 'UploadReceipt',
    read: 'ReadReceipt',
}