<template>
    <div class="d-flex p-3">
        <div class="w-25 m-4">
            <div class="bg-white rounded-5 left-panel-section">
                <MemberChangeDropdown :page="page" @action="goToPage" :currentClientId="currentClientId"
                    :isVertical="true" :showOnlyRelatedClients="true" @back="back()" />
            </div>
        </div>
        <div class="w-75 m-4 position-relative">
            <div class="rounded-5 d-flex flex-column right-panel-section">
                <div v-if="isClientAssigned"
                    class="bg-white d-flex justify-content-end h-100 flex-column m-4 right-panel-internal-div"
                    id="right-panel">
                    <div class="d-grid justify-content-center align-items-center h-100" v-if="!messages">
                        <b-spinner variant="primary"></b-spinner>
                    </div>
                    <div class="d-grid justify-content-center align-items-center h-100"
                        v-else-if="messages && messages.length == 0">
                        <h3>No Previous Messages</h3>
                    </div>
                    <div class="d-flex flex-column message-section mt-4" id="messages"
                        v-else-if="messages && messages.length > 0">
                        <div v-for="message in messages" :key="message.id">
                            <template v-if="message.readAt || message.sentBy === sender">
                                <div class="mx-4 my-3 text-end read" v-if="message.type == 'Text'"
                                    :style="{'width':'fit-content','max-width':'600px', 'float':message.sentBy !== sender ? 'right' : ''}">
                                    <span class="message-sent-date d-flex"
                                        :style="{'justify-content':message.sentBy !== sender ? 'end':'start'}">{{
                                        message.sentAt }}</span>
                                    <div class="text-end"
                                        :style="{'background':'#ECF8F5','border-radius':message.sentBy !== sender?'40px 0 40px 40px':'0px 40px 40px 40px'}">
                                        <div class="py-3" style="padding-left: 20px; padding-right: 50px;">
                                            <div class="text-start">
                                                <p class="m-0 message-text">{{ message.text }}</p>
                                            </div>
                                            <div v-if="message.sentBy !== sender"
                                                class="text-start d-flex justify-content-start status">
                                                <span class="font-weight-bold">{{ message.readAt }}</span>
                                                <span class="mx-3 font-weight-bold">Read</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mx-4 my-3 text-end read" v-if="message.type == 'Audio'"
                                    :style="{'width':'fit-content','max-width':'600px', 'float':message.sentBy !== sender ? 'right' : ''}">
                                    <span class="message-sent-date d-flex"
                                        :style="{'justify-content':message.sentBy !== sender ? 'end':'start'}">{{
                                        message.sentAt }}</span>
                                    <div class="text-start"
                                        :style="{'background':'#ECF8F5','border-radius':message.sentBy !== sender?'40px 0 40px 40px':'0px 40px 40px 40px'}">
                                        <div class="py-3" style="padding-left: 20px; padding-right: 50px;">
                                            <div class="text-start">
                                                <audio class="m-0" :src="message.data.url" controls></audio>
                                            </div>
                                            <div class="text-start d-flex justify-content-start status">
                                                <span class="font-weight-bold">{{ message.readAt }}</span>
                                                <span class="mx-3 font-weight-bold">Read</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="!message.readAt && message.sentBy !== sender">
                                <div class="mx-4 my-3 text-end unread" v-if="message.type == 'Text'"
                                    :style="{'width':'fit-content','max-width':'600px', 'float':message.sentBy !== sender ? 'right' : ''}">
                                    <span class="message-sent-date d-flex"
                                        :style="{'justify-content':message.sentBy !== sender ? 'end':'start'}">{{
                                        message.sentAt }}</span>
                                    <div class="text-end"
                                        :style="{'background':'#DFEBFF','border-radius':message.sentBy !== sender?'40px 0 40px 40px':'0px 40px 40px 40px'}">
                                        <div class="py-3" style="padding-left: 20px; padding-right: 50px;">
                                            <div class="text-start">
                                                <p class="m-0"
                                                    style="color: #018BD8; font-family: 'roboto'; font-size: 18px; max-width: 600px; word-wrap: break-word; word-break: break-all;">
                                                    {{ message.text }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mx-4 my-3 text-end unread" v-if="message.type == 'Audio'"
                                    :style="{'width':'fit-content','max-width':'600px', 'float':message.sentBy !== sender ? 'right' : ''}">
                                    <span class="message-sent-date d-flex"
                                        :style="{'justify-content':message.sentBy !== sender ? 'end':'start'}">{{
                                        message.sentAt }}</span>
                                    <div class="text-start"
                                        style="background: #DFEBFF; border-radius: 40px 0 40px 40px;">
                                        <div class="py-3" style="padding-left: 20px; padding-right: 50px;">
                                            <audio class="m-0" :src="message.data.url" controls></audio>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="mx-4 my-3 text-end unread d-flex justify-content-end" v-if="isLoading">
                            <div class="text-start d-flex w-25 justify-content-center" style="background: #DFEBFF; border-radius: 40px 0 40px 40px;">
                                <div class="py-3" style="padding-left: 20px; padding-right: 50px;" v-if="!audioBlobUrl">
                                    <b-spinner variant="primary"></b-spinner>
                                </div>
                                <div class="py-3" style="padding-left: 20px; padding-right: 50px;" v-if="audioBlobUrl">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <b-spinner variant="primary"></b-spinner>
                                        <span class="mx-3 message-uploading">Uploading Audio</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- v-if="!messages || (messages && messages.length < 0)" -->
                    </div>
                    <div class="mb-3">
                        <div style="margin: 2% 4%;border: 2px solid #CAD1D4; border-radius: 50px;">
                            <div class="d-flex justify-content-evenly px-2">

                                <input type="text" placeholder="Write your message here..." class="message-box w-80"
                                    style="font-size: 20px;background:transparent"
                                    v-if="!isAudioRecording && !audioRecorded" v-model="textMessage" id="message-text"
                                    v-on:keyup.enter="send">

                                <div class="w-100 d-flex align-items-center mx-1" style="font-size: 20px;"
                                    v-if="isAudioRecording">
                                    <b-tooltip target="stop" placement="bottom" title="Stop"></b-tooltip>
                                    <b-tooltip target="delete" placement="bottom" title="Delete"></b-tooltip>
                                    <div class="d-flex align-items-center justify-content-around h-85 rounded-5 w-60 text-white"
                                        style="background: #4D55C1;">
                                        <span class="mx-2 font-weight-bold">{{audioRecordedTime}}</span>
                                        <span class="mx-2" style="color: rgba(255, 255, 255, 0.7);">Record Your
                                            Message...</span>
                                        <span id="stop" class="material-symbols-outlined mx-1"
                                            style="color: rgba(255, 255, 255, 0.7); cursor: pointer;"
                                            @click="stopAudioRecording">
                                            stop_circle
                                        </span>
                                        <span id="delete" class="material-symbols-outlined"
                                            style="color: rgba(255, 255, 255, 0.7); cursor: pointer;"
                                            @click="clearAudioRecordedData">
                                            delete
                                        </span>
                                    </div>
                                </div>

                                <div class="w-100 d-flex align-items-center mx-1" style="font-size: 20px;"
                                    v-if="audioRecorded">
                                    <b-tooltip target="delete" placement="bottom" title="Delete"></b-tooltip>
                                    <div class="d-flex align-items-center justify-content-around h-85 rounded-5 w-50 text-white"
                                        style="background: #4D55C1;">
                                        <span class="mx-1 font-weight-bold">{{ audioRecordedTime }}</span>
                                        <span class="mx-2" style="color: rgba(255, 255, 255, 0.7);">Voice
                                            Recorded</span>
                                        <span id="delete" class="material-symbols-outlined mx-1"
                                            style="color: rgba(255, 255, 255, 0.7); cursor: pointer;"
                                            @click="clearAudioRecordedData">
                                            delete
                                        </span>
                                    </div>
                                </div>
                                <!-- <div>
                                    <b-tooltip target="start" placement="bottom" title="Add Link"></b-tooltip>
                                    <svg width="50" height="63" viewBox="0 0 63 63" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" @click="openLinkModal" id="start"
                                        class="cursor-pointer" style="outline: none;">
                                        <rect x="0.929688" y="0.649902" width="61.9101" height="61.9101" rx="30.9551"
                                            fill="#DFEBFF" />
                                        <g transform="translate(16, 16)">
                                            <path
                                                d="M10.59 13.41a2 2 0 102.83-2.83l-1.42-1.41a2 2 0 10-2.83 2.83l1.42 1.41z"
                                                fill="#4D55C1" />
                                            <path
                                                d="M3.51 11.05a5 5 0 117.07-7.07l1.42 1.41a5 5 0 01-7.07 7.07l-1.42-1.41zm7.07-5.66a3 3 0 10-4.24 4.24l1.41 1.41a3 3 0 104.24-4.24l-1.41-1.41z"
                                                fill="#4D55C1" />
                                            <path
                                                d="M20.49 12.95a5 5 0 11-7.07 7.07l-1.42-1.41a5 5 0 017.07-7.07l1.42 1.41zm-7.07 5.66a3 3 0 104.24-4.24l-1.41-1.41a3 3 0 10-4.24 4.24l1.41 1.41z"
                                                fill="#4D55C1" />
                                        </g>
                                    </svg>
                                </div> -->
                                <div v-if="!isAudioRecording && !audioRecorded">
                                    <b-tooltip target="start" placement="bottom" title="Start Recording"></b-tooltip>
                                    <svg width="50" height="63" viewBox="0 0 63 63" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" @click="startAudioRecording" id="start"
                                        class="cursor-pointer" style="outline: none;">
                                        <rect x="0.929688" y="0.649902" width="61.9101" height="61.9101" rx="30.9551"
                                            fill="#DFEBFF" />
                                        <path
                                            d="M44.8945 33.1298C44.1306 32.8662 43.3026 33.2755 43.0483 34.0393C41.8659 37.3504 39.2642 39.9517 35.9536 41.134C34.671 41.5889 33.3703 41.816 32.0878 41.8252H32.0059C31.9786 41.8252 31.9421 41.8252 31.9149 41.8344C27.1395 41.798 22.6648 38.8146 20.9634 34.0485C20.9634 34.0393 20.9542 34.0212 20.9542 34.012C20.6721 33.257 19.8353 32.8751 19.0806 33.1571C18.3256 33.4391 17.9436 34.276 18.2257 35.0307C20.1538 40.3882 24.9748 44.1086 30.5505 44.6633V48.2833C30.5505 49.0836 31.2052 49.7387 32.006 49.7387C32.8063 49.7387 33.4614 49.084 33.4614 48.2833V44.6633C39.0372 44.0992 43.8672 40.3882 45.7862 35.0307C45.7954 35.0215 45.7954 35.0034 45.7954 34.9942C46.0584 34.2214 45.6584 33.3934 44.8941 33.1298L44.8945 33.1298Z"
                                            fill="#4D55C1" />
                                        <path
                                            d="M32.0054 13.3105C27.5302 13.3105 23.9102 16.9305 23.9102 21.4058V30.1556C23.9194 34.6217 27.5394 38.2416 32.0054 38.2509C36.4714 38.2417 40.0914 34.6217 40.1007 30.1556V21.4058C40.1007 16.9398 36.4807 13.3105 32.0054 13.3105ZM37.19 30.1556C37.19 33.0209 34.8707 35.3402 32.0054 35.3402C29.1402 35.3402 26.8208 33.0209 26.8208 30.1556V30.0465H30.5956C31.396 30.0465 32.0511 29.3917 32.0511 28.591C32.0511 27.7906 31.3963 27.1355 30.5956 27.1355L26.8208 27.1358V24.4254H32.5238C33.3242 24.4254 33.9793 23.7706 33.9793 22.9699C33.9793 22.1695 33.3245 21.5144 32.5238 21.5144L26.8208 21.5147V21.4056C26.8208 18.5403 29.1402 16.221 32.0054 16.221C34.8707 16.221 37.19 18.5403 37.19 21.4056V30.1556Z"
                                            fill="#4D55C1" />
                                    </svg>
                                </div>

                                <div>
                                    <b-tooltip target="send" placement="bottom" title="Send"></b-tooltip>
                                    <svg width="50" height="63" viewBox="0 0 63 63" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" id="send" class="cursor-pointer"
                                        @click="send" style="outline: none;">
                                        <rect x="0.953125" y="0.652344" width="61.9101" height="61.9101" rx="30.9551"
                                            fill="#4D55C1" />
                                        <path
                                            d="M43.1441 20.4941L33.1563 40.4722L16.5068 29.6515L43.1441 20.4941ZM45.5719 17.3213C45.4588 17.3213 45.3448 17.3388 45.233 17.3783L13.4018 28.321C13.0232 28.4515 12.7522 28.7861 12.703 29.1822C12.6548 29.5783 12.8358 29.9699 13.1715 30.1871L32.679 42.8671C32.9183 43.0228 33.1946 43.1019 33.4733 43.1019C33.6017 43.1019 33.73 43.0854 33.8572 43.0515C34.2577 42.9418 34.5923 42.6675 34.7789 42.2967L46.509 18.8354C46.6978 18.4546 46.6396 17.9973 46.3587 17.6779C46.158 17.4486 45.8683 17.3214 45.572 17.3214L45.5719 17.3213Z"
                                            fill="#FFFEFF" />
                                        <path
                                            d="M26.1985 37.7875C24.3038 36.8505 24.9808 35.8194 24.9808 35.8194C24.9808 35.8194 32.7832 29.0852 35.4437 26.5543C35.5358 26.4666 35.5524 26.3251 35.481 26.2198C35.4118 26.1124 35.1848 26.0717 35.0695 26.1233L18 32.6804V44.8515C18 45.263 18.3301 45.637 18.7031 45.8071C19.0783 45.9771 19.5622 45.9135 19.8726 45.6436L27.8199 38.741C27.2902 38.3953 26.7691 38.0696 26.1985 37.7874L26.1985 37.7875Z"
                                            fill="#FFFEFF" />
                                    </svg>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isClientAssigned" class="bg-white d-flex justify-content-end h-100 flex-column m-4"
                    style="overflow: auto; border-radius: 10px; box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15); opacity: .95;">
                    <div class="d-flex justify-content-center align-items-center h-100">
                        <h3>You are not assigned as Counselor for this member. Hence messages can't be fetched.</h3>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal for adding/editing link -->
        <!-- <b-modal v-model="showLinkModal" title="Add/Edit Link" hide-footer>
            <b-form @submit.prevent="submitLink">
                <b-form-group label="Text" label-for="text-input">
                    <b-form-input id="text-input" v-model="currentLinkText" required></b-form-input>
                </b-form-group>

                <b-form-group label="Link" label-for="link-input">
                    <b-form-input id="link-input" v-model="currentLinkUrl" required></b-form-input>
                </b-form-group>

                <b-button class="primary" :class="{ 'disabled-content': disableSubmit }" type="submit"
                    style="margin-top:10px;">Save</b-button>
            </b-form>
        </b-modal> -->


    </div>
</template>

<script>
import MemberChangeDropdown from '@/components/general/dropdownButtons/MemberChangeDropdown.vue';
import { AudioRecordingService } from '@/util/services/audioRecordingService.js';
import { uploadMediaService, getRealtimeChat, getConversations, getMessagesList, saveAudioMessage, sendMessageData } from '@/util/helpers/graphql/messaging.graphql.js';
import { getContactDetails } from '@/util/helpers/graphql/family-member.graphql.js';
import { Time, Receipt, MessageType, Message } from './../../util/enum/common.js'
import { clients } from '@/util/apiRequests';
import { contactDetails } from '@/util/helpers/graphql/common.graphql.js';
import moment from "moment";


export default {
    name: 'Messaging',
    props: {
        client_id: [String, Number]
    },
    components: {
        MemberChangeDropdown
    },
    data() {
        return {
            audioBlob: null,
            audioName: null,
            audioBlobUrl:null,
            recordingService: null,
            isAudioRecording: false,
            audioRecordedTime: null,
            audioRecorded: false,
            totalAudioRecordedTime: null,
            message: null,
            clientData: null,
            familyId: null,
            contactId: null,
            conversationId: null,
            sender: null,
            receiver: null,
            messages: null,
            contact: null,
            textMessage: null,
            showLinkModal: false, 
            currentLinkText: "", 
            currentLinkUrl: "", 
            editingLinkIndex: null,
            linkMapping: [], 
            seniorContactId: null,
            uploadDetails: null,
            isLoading: false,
            lastMessageId: null,
            isEmpty: false,
            currentClientId: this.$route.params.client_id,
            isClientAssigned: false,
            scrollPosition: 0,
            fetchingOlderMessages: false,
            page: {
                 label: 'Messaging',
                 name: 'Messaging',
            },
        }
    },
    methods: {
        async init() {
            this.messages = null;
            this.isClientAssigned = await this.getClient();
            this.linkMapping=[];

            if( this.isClientAssigned ) {
                const seniorContactDetails = await this.getContactDetails();
                this.seniorContactId = seniorContactDetails.contactId;
                this.seniorFamilyId = seniorContactDetails.familyId;
                this.contactId = localStorage.getItem('sessionContactId');
                this.getConversations();
                this.getContact();

                this.chatSubscribe(this.contactId);
            }
            else {
                this.$toasted.error( "You are not assigned as Counselor for this member. Hence messages can't be fetched." );
            }
            
            this.recordingService = new AudioRecordingService();
            this.recordingService.getRecordedBlob()
            .subscribe(async (data) => {
                // let audioBlob = data.blob;
                // let audioName = data.title;
                this.audioBlobUrl = URL.createObjectURL(data.blob)
            });

            this.recordingService.recordingFailed().subscribe(() => {
                this.isAudioRecording = false;
            });

            this.recordingService.getRecordedTime().subscribe((time) => {
                this.audioRecordedTime = time;
            });

            this.recordingService.getRecordedBlob().subscribe((data) => {
                this.audioBlob = data.blob;
                this.audioName = data.title;
                this.audioBlobUrl = URL.createObjectURL(data.blob);
            });
        },
        startAudioRecording() {
            if (!this.isAudioRecording) {
                this.isAudioRecording = true;
                this.recordingService.startRecording();
            }
        },
        openLinkModal() {
            // Open the modal, optionally pre-fill data for editing
            this.showLinkModal = true;
            this.currentLinkText = "";
            this.currentLinkUrl = "";
        },
        insertLinkInTextMessage(text, link) {
          this.textMessage = this.textMessage+text;
        },
        submitLink() {
            const existingLinkIndex = this.linkMapping.findIndex(link => link.text === this.currentLinkText && link.link === this.currentLinkUrl);

            if (existingLinkIndex !== -1) {
                this.linkMapping.splice(existingLinkIndex, 1);

            }

            // Add new link to linkMapping
            this.linkMapping.push({
                text: this.currentLinkText,
                link: this.currentLinkUrl,
            });

            // Insert underlined link text in the message
            this.insertLinkInTextMessage(this.currentLinkText, this.currentLinkUrl);
            this.showLinkModal = false;
        },
        abortAudioRecording() {
            if (this.isAudioRecording) {
                this.isAudioRecording = false;
                this.audioRecorded = false;
                this.recordingService.abortRecording();
            }
        },
        stopAudioRecording() {
            if (this.isAudioRecording) {
                this.totalAudioRecordedTime = this.audioRecordedTime;
                this.recordingService.stopRecording();
                this.isAudioRecording = false;
                this.audioRecorded = true;
            }
        },
        clearAudioRecordedData() {
            if (this.isAudioRecording) {
                this.abortAudioRecording();
            }
            this.audioBlobUrl = null;
            this.audioRecorded = false;
        },
        blobToFile( theBlob, fileName )  {
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        formatTime( ms ) {
            const seconds = Math.floor((ms / 1000) % 60);
            const minutes = Math.floor((ms / 1000 / 60) % 60);
            const hours = Math.floor((ms / 1000 / 3600) % 24);
            return [hours, minutes, seconds];
        },
        async getClient() {
            try {
                const res = await this.$api.get(clients.getClient(this.currentClientId));
                // if( res['data'].length == 0 ) throw new Error("Client information not found !");
                this.clientData = res.data?.[0];
                const relatedClients = JSON.parse(localStorage.getItem('related-clients'));
                const contactExists = relatedClients.membersList.filter( el => el.email == this.clientData.email );
                if( contactExists.length > 0 ) {
                    return true;
                }
                return false;
            } catch (error) {
                this.$cl(error);
            }
        },
        async getContactDetails() {
            try {
                const email = this.clientData.email;
                const seniorContactDetails = await contactDetails( email );
                return {
                    familyId: seniorContactDetails.familyId,
                    contactId: seniorContactDetails.contactId
                }
            }
            catch( e ) {
                this.$toasted.error( e );
            }
        },
        async getConversations() {
            try {
                this.messages = null;
                this.lastMessageId = null;
                this.getFamily();
                const conversion = await getConversations(this.seniorFamilyId, this.seniorContactId)
                this.conversationId = conversion.conversationId;
                if (this.seniorContactId == conversion.participants[1]) {
                    this.sender = conversion.participants[1];
                    this.receiver = conversion.participants[0];
                } else if (this.seniorContactId == conversion.participants[0]) {
                    this.receiver = conversion.participants[1];
                    this.sender = conversion.participants[0];
                }

                this.getMessages(this.conversationId, this.lastMessageId, Message.limit);
            }
            catch( err ) {
                this.$toasted.error( "Failed to get conversation." );
            }
        },
        async getMessages(conversationId, lastMessageId, limit) {
            let messageList = await getMessagesList(conversationId, lastMessageId, limit)
            if (messageList.length) {
                this.messages = [];
                // let messageList = res.data.getMessages;
                this.lastMessageId = messageList[messageList.length - 1].messageId;

                messageList.forEach(value => {
                    //get read at time
                    let readTimeStamp = value.readAt;
                    if (value.readAt) {
                        const current_time = new Date().getTime(),
                            read_time = value.readAt,
                            diff = current_time - readTimeStamp;
                        let different = this.formatTime(diff);

                        if (different[0] < 1) {
                            value.readAt = `${different[1]} ${Time.min}`;
                        } else if (different[0] >= 1 && different[0] < 24) {
                            value.readAt = `${different[0]} ${Time.readHour}`;
                        } else {
                            value.readAt = Time.custom;
                        }
                        value.readAt = moment.unix(readTimeStamp / 1000).format("DD-MM-YYYY hh:mm A");
                    }

                    let timestamp = value.sentAt;
                    value.sentAt = moment.unix(timestamp / 1000).format("DD-MM-YYYY hh:mm A");

                    let data = value?.data;
                    if (data) {
                        value.data = JSON.parse(data);
                    }

                    this.messages.push(value);
                });

                if (messageList.length == limit) {
                    this.getMessages(this.conversationId, this.lastMessageId, limit);
                }

                this.scrollToBottom();
                this.scrollbarListener()

            } else if (messageList.length == 0) {
                this.messages = [];
            }
        },
        async sendMessage(conversationId, message,linkmapping, messageType, details) {
            const getUtcTimestamp = new Date().getTime();

            const messageResponse = await sendMessageData(conversationId, getUtcTimestamp,message,JSON.stringify(linkmapping), messageType, details)

            message = messageResponse;
            let timestamp = message.sentAt;
            message.sentAt = moment.unix(timestamp / 1000).format("DD-MM-YYYY hh:mm A");
            
            let data = message?.data;
            if (data) {
                message.data = JSON.parse(data);
                message.data['url'] = this.audioBlobUrl;
            }
            
            this.textMessage = null;
            if (messageType == MessageType.audio) {
                this.audioBlobUrl = null;
                this.audioRecorded = false;
            }

            this.messages.push( message );
            return true;
        },
        async send() {
            this.isLoading = true;
            const message = this.textMessage ? this.textMessage.trim() : null;
            this.textMessage = null;
            let linkmapping = this.linkMapping;
            this.linkMapping=[];


            this.scrollToBottom();
            if (this.audioBlobUrl) {
                const data = await uploadMediaService(this.seniorFamilyId, this.conversationId)
                this.uploadDetails = data.media[0];
                let uploadUrl = data.media[0].uploadUrl;
                let file = this.blobToFile(this.audioBlob, this.audioName);
                await this.uploadAudio(uploadUrl, file);
                await this.sendMessage(this.conversationId, message,linkmapping, MessageType.audio, this.uploadDetails);
                this.isLoading = false;
            } else if (!message) {
                this.$toasted.error('Message can’t be empty!');
                this.isLoading = false;
            } else {
                await this.sendMessage(this.conversationId, message,linkmapping, MessageType.text, this.uploadDetails);
                this.isLoading = false;
            }
        },
        async chatSubscribe(contactId) {
            const chatSubscription = await getRealtimeChat(contactId);
            chatSubscription.subscribe(res => {
                let subscription = res.value.data.subscribeToConversation;
                if (this.conversationId == subscription.conversationId && subscription.type != Receipt.upload) {
                    //get read at time

                    const isMessageAdded = this.messages.find(message=>message.messageId===subscription.messageId);
                    if(!isMessageAdded){
                        let value = subscription;
                        let timestamp = value.sentAt;
                        value.sentAt = moment.unix(timestamp / 1000).format("DD-MM-YYYY hh:mm A")
                        let data = value?.data;
                        if (data) {
                            value.data = JSON.parse(data);
                        }

                        this.messages.push(value);

                        // Scroll down
                        this.scrollToBottom()
                    }
                    if (subscription.type == Receipt.read) {
                        this.messages = this.messages.map( message => {
                            const subscriptionMessageId = subscription.messageId;
                            if (subscriptionMessageId == message.messageId) {
                                const current_time = new Date().getTime(),
                                    read_time = subscription.readAt,
                                    diff = current_time - read_time;
                                let different = this.formatTime(diff);
                                if (different[0] < 1) {
                                    message.readAt = `${different[1]} ${Time.min}`;
                                } else if (different[0] >= 1 && different[0] < 24) {
                                    message.readAt = `${different[0]} ${Time.readHour}`;
                                } else {
                                    message.readAt = Time.custom;
                                }
                            }
                            return message;
                        } )
                    }
                }
            })
        },
        getFamily() {
            this.familyId = localStorage.getItem('familyId');
            this.contactId = localStorage.getItem('sessionContactId');
        },
        getContact() {
            getContactDetails(this.seniorFamilyId, this.seniorContactId)
            .then((res) => {
                this.contact = res.data.getContact;
            })
            .catch((error) => {
                console.error(error);
            });
        },
        async uploadAudio(url, file) {
            await saveAudioMessage(url, file);
        },
        scrollToBottom() {
            setTimeout(() => {
                const element = document.getElementById("messages");
                element.scrollTop = element.scrollHeight;
            }, 500)
        },
        clearMessage() {
            this.clearAudioRecordedData();
            this.textMessage = null;
            this.uploadDetails = null;
            
        },
        goToPage(memberChangeResponse) {
            this.messages = null;
            this.$router.push(memberChangeResponse.page);
            this.currentClientId = memberChangeResponse.page.params.client_id;
            this.init();
        },
        back() {
            const page = {
                label: "ClientOverview",
                name: "ClientOverview", "params": { "record_id": this.currentClientId }
            }
            this.$router.push(page);
        },
        scrollbarListener() {
            const messagesDiv = document.getElementById("messages");
            if( messagesDiv ) {
                messagesDiv.addEventListener('scroll', () => {
                    if( messagesDiv.scrollTop === 0 ) {
                        this.fetchingOlderMessages = true;
                        // this.getConversations();
                    }
                })
            }
        }
    },
    async created() {
        this.currentClientId = this.client_id;
        // this.init();
    },
    async mounted() {
        await this.chatSubscribe(this.contactId);
    },
}
</script>

<style scoped>
    .message-box {
        box-shadow: none;
    }

    .message-box:hover {
        box-shadow: none;
        outline: none;
        border: 0;
    }

    .message-box:focus {
        box-shadow: none;
        outline: none;
        border: 0;
    }

    .unread audio::-webkit-media-controls-panel {
        background-color: #DFEBFF;
    }

    .unread audio::-webkit-media-controls-play-button {
        background-color: #018BD8;
        border-radius: 50%;
    }

    .unread audio::-webkit-media-controls-play-button:hover {
        background-color: rgba(177,212,224, .7);
    }
    
    .read audio::-webkit-media-controls-panel {
        background-color: #ECF8F5;
    }

    .read audio::-webkit-media-controls-play-button {
        background-color: #4CBC9A;
        border-radius: 50%;
    }

    .read audio::-webkit-media-controls-play-button:hover {
        background-color: rgba(177, 224, 195, 0.7);
    }
    
    .left-panel-section {
        height: 750px; 
        opacity: .95;
    }

    .right-panel-section {
        height: 750px;
        background: rgba(255, 255, 255, 0.95);
    }

    .right-panel-internal-div {
        overflow: auto; 
        border-radius: 10px; 
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15); 
        opacity: .95;
    }

    .message-section {
        height: 600px; 
        overflow: auto;
    }

    .message-sent-date {
        color: #ADADAD; 
        font-family: 'roboto'; 
        font-size: 16px;
    }

    .read .message-text {
        color: #4CBC9A; 
        font-family: roboto; 
        font-size: 18px; 
        max-width: 600px; 
        word-wrap: break-word;
    }

    .read .status {
        color: #9A9C9C; 
        font-family: roboto; 
        font-size: 14px;
    }

    .message-uploading {
        color: #000; 
        font-family: 'roboto'; 
        font-size: 16px;
    }

    .underlined-link {
    text-decoration: underline;
    color: #018BD8;
    cursor: pointer;
}



</style>
